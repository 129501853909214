.rbc-calendar {
  border: 0;
  font-family: 'Figtree';
}

.rbc-toolbar {
  background-color: #fff;
  padding: 15px 10px;
  font-size: 14px;
  font-family: 'figtree';
  font-weight: 500;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  color: white;
  border-bottom: 1px solid #ecf8f7;
}
.rbc-toolbar-label {
  color: #00a58e;
  font-weight: 500;
  font-family: 'Figtree';
}
.rbc-header {
  color: #00a58e;
  font-size: 16px;
  font-family: 'Figtree';
}
.rbc-btn-group {
  padding: 3px;
  border: 1px solid #ecf8f7;
  border-radius: 6px;
}
.rbc-btn-group > button {
  color: #00a58e;
  border: 0;
}
.rbc-btn-group > button:hover {
  background-color: #ecf8f7;
  color: #00a58e;
}
.rbc-btn-group > .rbc-active {
  background-color: #00a58e !important;
  color: #fff;
  border: 0;
  border-radius: 6px;
}
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #ecf8f7;
  color: #fff;
}

.rbc-month-view {
  border: 0;
  padding: 15px 10px;
}
.rbc-off-range-bg {
  background: transparent;
}
.rbc-off-range {
  color: inherit;
}
.rbc-event {
  background-color: #ecf8f7;
  color: #00a58e;
  padding: 2px;
}
.rbc-event:focus {
  outline: none;
}
.rbc-event.rbc-selected {
  background-color: #ecf8f7 !important;
}
.rbc-row-segment .rbc-event-content {
  font-size: 14px;
  font-weight: 500;
}
.rbc-button-link {
  font-size: 14px;
  text-align: center;
}
.rbc-today {
  background-color: #fff;
  color: #00a58e;
}
.rbc-show-more {
  color: #333;
}
.rbc-time-view {
  border: 1px solid #ecf8f7 !important;
}
.rbc-time-header.rbc-overflowing {
  border: 1px solid #ecf8f7;
}
.rbc-time-content {
  border-top: 1px solid #ecf8f7;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #ecf8f7;
}
.rbc-agenda-date-cell {
  border-right: 1px solid #ecf8f7;
}
.rbc-agenda-table {
  border: 1px solid #ecf8f7;
}
.rbc-agenda-event-cell,
.rbc-agenda-time-cell,
.rbc-agenda-date-cell {
  font-size: 14px;
  font-family: 400;
  border-bottom: 1px solid #ecf8f7;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 15px 10px;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 10px 10px;
  color: #00a58e;
  font-weight: 500;
  font-size: 16px;
  background-color: #ecf8f7;
}
.rbc-month-row {
  overflow: inherit;
}
.rbc-time-slot {
  color: #333;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ecf8f7;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ecf8f7;
}
.rbc-header,
.rbc-timeslot-group {
  border-bottom: 1px solid #ecf8f7;
}
.rbc-header + .rbc-header,
.rbc-time-content > * + * > * {
  border-left: 1px solid #ecf8f7;
}
.rbc-day-slot .rbc-time-slot {
  border: 0;
}
.rbc-agenda-view table.rbc-agenda-table {
  border-bottom: 1px solid #ecf8f7;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th,
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-bottom: 1px solid #ecf8f7;
}
.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid #ecf8f7;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ecf8f7;
}
.rbc-current-time-indicator {
  background-color: #fff;
}
.rbc-agenda-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00a58e;
  font-size: 16px;
}

@media (max-width: 990px) {
  .rbc-toolbar {
    flex-direction: column-reverse;
  }
  .rbc-calendar {
    height: 500px !important;
  }
}
