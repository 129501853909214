.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #00a58e;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 70deg,
      #0000 71deg 90deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 calc(100% - 8px)
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: s5 1s infinite;
}
@keyframes s5 {
  to {
    transform: rotate(0.5turn);
  }
}
