@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.auth-bg {
  background-image: url(./assets/images/auth-bg.png);
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.blue-bg {
  right: 0;
  background: #0172cb;
  mix-blend-mode: normal;
  opacity: 0.9;
  min-height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
}
.transition {
  transition: all 1s ease !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.custom-scroll-bar {
  scrollbar-width: thin;
  scrollbar-color: #e8edf1 #ffffff;
}

/* Chrome, Edge, and Safari */
.custom-scroll-bar::-webkit-scrollbar {
  width: 12px;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 50px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #e8edf1;
  border-radius: 9px;
  border: 3px solid #ffffff;
}
.dashboard-gradient-bg {
  background-image: linear-gradient(
    45deg,
    hsl(198deg 95% 85%) 0%,
    hsl(198deg 90% 83%) 10%,
    hsl(198deg 85% 82%) 20%,
    hsl(198deg 82% 80%) 30%,
    hsl(198deg 78% 79%) 40%,
    hsl(197deg 75% 78%) 50%,
    hsl(209deg 82% 75%) 60%,
    hsl(219deg 88% 73%) 70%,
    hsl(226deg 93% 69%) 80%,
    hsl(234deg 97% 65%) 90%,
    hsl(248deg 100% 52%) 100%
  );
  background-repeat: no-repeat;
  width: 100%;
}
html {
  scroll-behavior: smooth;
}
/* Pagination */
.page-link {
  border: 0px;
  border-radius: 50%;
  padding: 0.5rem 0.85rem;
  /* color:linear-gradient(34deg, rgba(83, 167, 155, 1) 14%, rgba(34, 133, 182, 1) 50%); */
}
.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 50%;
}
.pagination {
  gap: 10px;
}
.page-item.active .page-link {
  background: none;
  border-color: transparent;
  color: #00a58e;
  font-weight: 600;
  background-color: #ecf8f7;
  border-radius: 50%;
  border: 1px solid #00a58e;
}
.page-item.disabled > .page-link {
  color: gray !important;
}
/* Doc viewer */
#pdf-controls path,
#pdf-controls polygon {
  fill: #fff;
}
#no-renderer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  width: 100%;
}
