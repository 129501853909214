/* Mobile Side Bar */
.mobile-sidebar{
   display: none;
    position: fixed;
    top: 0;
    right: 800px;
    z-index: 200;
    background-color:#ECF8F7;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    transition: all 1s ease !important;
}
.menu-active {
    right: 0;
    width: 100%;
    transition: all 1s ease !important;
}
.active > div> h5 {
  color: #00A58E !important;
  font-weight: 700  ;
}
.active > div >svg >path {
  fill: #00A58E;
}

@media (max-width:767px) {
  .mobile-sidebar{
    display: block;
  }
}