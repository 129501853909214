.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 110;
  background: rgba(0, 0, 0, 0.75);
}
.card {
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 100;
  overflow: hidden;
}
.header {
  background-color: #00a58e;
  padding: 12px;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .modal {
    /* left: calc(50% - 20rem); */
    width: 50rem;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .modal {
    width: 90%;
  }
}

@media (min-width: 1536px) {
  .modal {
    /* left: calc(50% - 20rem); */
    width: 60rem;
  }
  .content {
    padding: 20px;
  }
  .header {
    padding: 16px 20px;
  }
}
